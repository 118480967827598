import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import VALIDATE_ZIP_CODE from '@/API/seoSegmentationValidateZipCode';
import {
  validateZipCode as ValidateZipCodeResults,
  validateZipCodeVariables as ValidateZipCodeVariables,
} from '@/__generated__/ValidateZipCode';

export default function useZipCodeValidation() {
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [hasError, setHasError] = useState(false);

  const [validateZipCode] = useLazyQuery<ValidateZipCodeResults, ValidateZipCodeVariables>(
    VALIDATE_ZIP_CODE,
    {
      variables: {
        input: { zipcode: zipCode },
      },
    }
  );

  const validate = async (newZipCode: string = '') => {
    setHasError(false);
    setZipCode(newZipCode);

    if (newZipCode.length < 5) {
      setCity('');
      setState('');
      setHasError(false);
      return Promise.resolve({ zipCode, city: '', state: '' });
    }

    if (newZipCode.length === 5) {
      const response = await validateZipCode({
        variables: {
          input: { zipcode: newZipCode },
        },
      });

      const res = response?.data?.seoSegmentationValidateZipCode;

      if (res?.__typename === 'SEOValidateZipCodeSuccess') {
        setCity(res?.city);
        setState(res?.state);
        return Promise.resolve({ zipCode: newZipCode, city: res?.city, state: res?.state });
      }

      if (newZipCode.length === 5 && res?.error?.__typename === 'InvalidSEOZipCodeError') {
        setCity('');
        setState('');
        setHasError(true);
        return Promise.resolve({ zipCode: newZipCode, city: '', state: '' });
      }
    }

    return Promise.resolve({ zipCode, city, state });
  };

  return {
    validate,
    hasError,
  };
}
