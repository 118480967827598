import { Button } from '@mui/material';
import { trackAmplitudeEvent, AmplitudeEvent } from '@/lib/analyticsHelper';

interface JoinToContactProps {
  ctaText: string;
  enrollmentURL: string;
}

const JoinToContact = ({ ctaText, enrollmentURL }: JoinToContactProps) => {
  const handleJoinCTA = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const amplitudeDataCTA = {
      cta_text: e.currentTarget.textContent,
      cta_type: 'button',
    };
    trackAmplitudeEvent(AmplitudeEvent.ctaInteracted, amplitudeDataCTA);
    return true;
  };

  return (
    <Button
      href={enrollmentURL}
      color="primary"
      variant="contained"
      size="large"
      onClick={handleJoinCTA}
      fullWidth
      rel="nofollow">
      {ctaText}
    </Button>
  );
};

export { JoinToContact };
