import { gql } from '@apollo/client';

const VALIDATE_ZIP_CODE = gql`
  query validateZipCode($input: SEOValidateZipCodeInput!) {
    seoSegmentationValidateZipCode(input: $input) {
      ... on SEOValidateZipCodeSuccess {
        __typename
        city
        state
      }
      ... on SEOValidateZipCodeError {
        __typename
        error {
          message
          ... on DataNotAvailableError {
            message
          }
          ... on DataNotAvailableForGivenPageNumberError {
            message
          }
          ... on UrlNotFoundError {
            message
          }
          ... on InvalidSEOZipCodeError {
            message
          }
        }
      }
    }
  }
`;

export default VALIDATE_ZIP_CODE;
