import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { SafetyModal } from '@care/caregiver-profile-components';
import { UsStateCode } from '@/__generated__/globalTypes';
import { getZipcodeFromGeoInfo } from '@/utils/getZipcodeFromGeoInfo';
import { usePageAttributes } from './contexts/PageAttributesContext';
import useZipCodeValidation from './hooks/useZipCodeValidation';

interface SafetyModalWrapperProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  isCaregiverProfilePage?: boolean;
  caregiverStateCode?: string | null;
}

const SafetyModalWrapper = ({
  openModal,
  setOpenModal,
  isCaregiverProfilePage = false,
  caregiverStateCode = null,
}: SafetyModalWrapperProps) => {
  const [isFromMassachusetts, setIsFromMassachusetts] = useState<boolean>(false);
  const { userGeoInfo, seoSegmentId } = usePageAttributes();
  const { validate } = useZipCodeValidation();

  const getStateCodeFromSegmentId = (segmentId: string): string | null => {
    const segment = segmentId.split('/').pop();

    if (segment?.includes('-')) {
      const state = segment.split('-').pop();
      return state?.toUpperCase() ?? null;
    }

    return null;
  };

  const stateCodeFromSegmentId = getStateCodeFromSegmentId(seoSegmentId);

  useEffect(() => {
    const fetchStateLocation = async () => {
      const zipCode = getZipcodeFromGeoInfo(userGeoInfo);
      const data = await validate(zipCode);

      if (data?.state === UsStateCode.MA) {
        setIsFromMassachusetts(true);
      }
    };

    if (isCaregiverProfilePage) {
      setIsFromMassachusetts(caregiverStateCode === UsStateCode.MA);
    } else {
      setIsFromMassachusetts(stateCodeFromSegmentId === UsStateCode.MA);
    }

    fetchStateLocation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SafetyModal
      open={openModal}
      onClose={() => setOpenModal(false)}
      newSafetyExperienceEnabled
      isMAProviderOrSeeker={isFromMassachusetts}
    />
  );
};

export { SafetyModalWrapper };
