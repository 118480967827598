import { useState } from 'react';
import getConfig from 'next/config';
import { CoreGlobalFooter } from '@care/navigation';
import { Box, useMediaQuery, type Theme } from '@mui/material';
import { AboutCaregiverSection } from '@/components/CaregiverProfile/Sections/AboutCaregiverSection';
import AvailabilitySection from '@/components/CaregiverProfile/Sections/AvailabilitySection';
import BreadcrumbsSection from '@/components/CaregiverProfile/Sections/BreadcrumbsSection';
import { SimilarCaregiverSection } from '@/components/CaregiverProfile/Sections/SimilarCaregiversSection';
import { MostRecentReviewSection } from '@/components/CaregiverProfile/Sections/MostRecentReviewSection';
import { SafetyCardSection } from '@/components/CaregiverProfile/Sections/SafetyCardSection';
import SafetySection from '@/components/CaregiverProfile/Sections/SafetySection';
import ZipCodeCTASection from '@/components/CaregiverProfile/Sections/ZipCodeCTASection';
import QualitiesSection from '@/components/CaregiverProfile/Sections/QualitiesSection';
import CaregiverCard from '@/components/CaregiverProfile/CaregiverCard';
import NavBar from '@/components/SeoGlobalNavbar';
import getProviderMetadata from '@/utils/getProviderMetadata';
import generateEnrollmentUrl from '@/utils/generateEnrollmentUrl';
import { SERVICE_SHORT_NAMES, SEO_SERVICE_MAP_SHORT_NAMES } from '@/constants';
import { JoinToContactSection } from '@/components/CaregiverProfile/Sections/JoinToContactSection';
import { ReviewsModal } from '@/components/CaregiverProfile/ReviewsModal';
import { ServicesSection } from '@/components/CaregiverProfile/Sections/ServicesSection';
import { ProfilePageSchema } from '@/components/CaregiverProfile/ProfilePageSchema';
import { CaregiverDetailDataProps } from './caregiverDetailPageProps';
import useAmplitudeTracking from './hooks/useAmplitudeTracking';
import useMetaTags from './hooks/useMetaTags';
import MetaHead from './shared/MetaHead';

const UnifiedCaregiverDetail = ({
  pageData: data,
  serviceShortName,
  testName,
  deviceChar,
  seoSegmentId,
}: CaregiverDetailDataProps) => {
  const {
    publicRuntimeConfig: { NEXT_PUBLIC_APP_URL },
  } = getConfig();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const seoProfileUrl = NEXT_PUBLIC_APP_URL + seoSegmentId;

  const [openModal, setOpenModal] = useState(false);
  const {
    getCaregiver: {
      hasCareCheck,
      member,
      profiles,
      badges,
      placeInfo: neighborhood,
      recurringAvailability,
      educationDegrees,
    },
    getSEOSegmentationBreadcrumbs,
    revieweeMetrics,
    reviewsByReviewee,
    serviceProfileId,
    publicId,
  } = data;

  const breadcrumbs = getSEOSegmentationBreadcrumbs?.breadcrumbs;

  const activeProfile = ((caregiverProfiles) => {
    if (caregiverProfiles?.childCareCaregiverProfile) {
      return caregiverProfiles.childCareCaregiverProfile;
    }
    if (caregiverProfiles?.petCareCaregiverProfile) {
      return caregiverProfiles.petCareCaregiverProfile;
    }
    if (caregiverProfiles?.seniorCareCaregiverProfile) {
      return caregiverProfiles.seniorCareCaregiverProfile;
    }
    if (caregiverProfiles?.tutoringCaregiverProfile) {
      return caregiverProfiles.tutoringCaregiverProfile;
    }
    if (caregiverProfiles?.houseKeepingCaregiverProfile) {
      return caregiverProfiles.houseKeepingCaregiverProfile;
    }
    return null;
  })(profiles);

  const merchandizedJobInterests = ((caregiverProfiles) => {
    if (caregiverProfiles?.commonCaregiverProfile) {
      return caregiverProfiles.commonCaregiverProfile.merchandizedJobInterests;
    }

    return null;
  })(profiles);

  const availabilityFrequency =
    activeProfile && activeProfile.__typename !== 'PetCareCaregiverProfile'
      ? activeProfile.availabilityFrequency
      : null;

  const revieweeMetricsData =
    revieweeMetrics?.__typename === 'RevieweeMetricsPayload' && revieweeMetrics.metrics
      ? revieweeMetrics.metrics
      : null;

  const providerMetadata = getProviderMetadata({
    displayName: member.displayName,
    firstName: member.firstName,
    address: member.address
      ? {
          city: member.address.city,
          state: member.address.state,
        }
      : null,
    yearsOfExperience: activeProfile ? activeProfile.yearsOfExperience : null,
    availability: availabilityFrequency,
    // render Care's logo if provider doesn't have profile picture
    image: member.hiResImageURL
      ? member.hiResImageURL
      : `${NEXT_PUBLIC_APP_URL}/img/care-no-tag.jpg`,
    serviceName: SEO_SERVICE_MAP_SHORT_NAMES[serviceShortName],
    hourlyRate: activeProfile?.payRange?.hourlyRateFrom.amount,
    bio: activeProfile ? activeProfile.bio.experienceSummary : null,
    totalReviews: revieweeMetricsData?.totalReviews ?? 0,
    isPremium: member.isPremium,
  });

  const caregiverList =
    data.seoSimilarCaregiverProfiles?.__typename === 'SEOSimilarCaregiverProfilesSuccess' &&
    data.seoSimilarCaregiverProfiles?.similarCaregiverProfiles
      ? data.seoSimilarCaregiverProfiles.similarCaregiverProfiles.caregiverList
      : null;

  const reviewsList =
    reviewsByReviewee?.__typename === 'ReviewsByRevieweePayload' ? reviewsByReviewee.reviews : [];

  const hasAiBio = profiles?.childCareCaregiverProfile?.bio.aiAssistedBio ?? false;

  useAmplitudeTracking<CaregiverDetailDataProps>({
    deviceChar,
    testName,
    hasCareCheck,
    serviceShortName,
    hasAiBio,
  });

  const metaTags = useMetaTags(providerMetadata);
  const enrollmentURL = generateEnrollmentUrl({
    serviceShortName,
    isMobile,
    serviceProfileId,
  });

  return (
    <Box data-testid="unified-profile">
      <MetaHead
        pageTitle={providerMetadata.title}
        metaTags={metaTags}
        seoProfileUrl={seoProfileUrl}
      />
      <ProfilePageSchema
        displayName={member.displayName}
        description={providerMetadata.description}
        imageURL={providerMetadata.image}
      />
      <NavBar enrollmentURL={enrollmentURL} />
      {breadcrumbs && <BreadcrumbsSection breadcrumbs={breadcrumbs} />}
      {!member.isPremium && (
        <ZipCodeCTASection
          enrollmentURL={enrollmentURL}
          serviceShortName={serviceShortName}
          areBreadcrumbsPresent={!!breadcrumbs}
        />
      )}
      <CaregiverCard
        profileImage={member.hiResImageURL}
        displayName={member.displayName}
        badges={badges}
        neighborhood={neighborhood}
        address={member.address}
        baseHourlyRate={activeProfile?.payRange?.hourlyRateFrom.amount}
        enrollmentURL={enrollmentURL}
        revieweeMetrics={revieweeMetricsData}
        experience={activeProfile?.yearsOfExperience || undefined}
      />
      {hasCareCheck && (
        <SafetyCardSection
          firstName={member.firstName}
          caregiverStateCode={member.address?.state ?? null}
        />
      )}
      <AboutCaregiverSection
        firstName={member.firstName}
        yearsOfExperience={activeProfile?.yearsOfExperience}
        experienceSummary={activeProfile?.bio.experienceSummary}
        isVaccinated={data.getCaregiver.isVaccinated}
        isAiAssistedBio={hasAiBio}
      />
      {(revieweeMetricsData || publicId) && (
        <MostRecentReviewSection
          reviews={reviewsList}
          averageRatings={revieweeMetricsData?.averageRatings ?? []}
          totalReviews={revieweeMetricsData?.totalReviews ?? 0}
          setOpenModal={setOpenModal}
          openModal={openModal}
          publicId={publicId}
          serviceShortName={SERVICE_SHORT_NAMES[serviceShortName]}
          firstName={member.firstName}
        />
      )}

      {activeProfile && merchandizedJobInterests && (
        <ServicesSection
          activeProfile={activeProfile}
          merchandizedJobInterests={merchandizedJobInterests}
          firstName={member.firstName}
        />
      )}
      {activeProfile && (
        <QualitiesSection
          activeProfile={activeProfile}
          educationDegrees={educationDegrees}
          firstName={member.firstName}
          languages={member.languages}
        />
      )}
      {recurringAvailability && (
        <AvailabilitySection
          firstName={member.firstName}
          recurringAvailability={recurringAvailability}
        />
      )}
      <SafetySection hasCareCheck={hasCareCheck} />
      {caregiverList && <SimilarCaregiverSection profiles={caregiverList} />}
      {isMobile && <JoinToContactSection ctaText="Join to contact" enrollmentURL={enrollmentURL} />}
      <Box mt={4} pb={8}>
        <CoreGlobalFooter />
      </Box>
      {revieweeMetricsData && reviewsByReviewee?.__typename === 'ReviewsByRevieweePayload' && (
        <ReviewsModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          reviews={reviewsByReviewee.reviews}
          averageRatings={revieweeMetricsData.averageRatings}
          totalReviews={revieweeMetricsData.totalReviews}
        />
      )}
    </Box>
  );
};

export default UnifiedCaregiverDetail;
